.DetailFilter {
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: dense;

  @mixin filterSection() {
    grid-column: span 2;

    select {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .DetailFilter__name {
    grid-column: span 4;

    input {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .DetailFilter__size {
    @include filterSection();
  }

  .DetailFilter__sex {
    @include filterSection();
  }

  @media only screen and (min-width: 0px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (min-width: 400px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (min-width: 700px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1100px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
